
import './App.css';
import Resume from "./Resume"

function App() {
  return (

    <Resume />

  );
}

export default App;
