import React, {useState} from 'react'
import "./Resume.css"
import ProfilePicture from "./placeholder_images/Profile.jpg"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faLink} from "@fortawesome/free-solid-svg-icons";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";


export default function Resume() {

    const resumeUrl = 'https://1drv.ms/b/s!AimQec-IYZ-8gZBegcXzA5tm6iuUmw?e=DWrwjb';
    const linkedInProfileUrl = 'https://www.linkedin.com/in/christellendaya/';
    const [isEmailVisible, setIsEmailVisible] = useState(false);

    const handleEnvelopeClick = () => {
        setIsEmailVisible(!isEmailVisible);
    };
    return (
        <div>
            {/*  ***********************  ABOUT / PROFILE  *********************** */}
            <header>
                <div className="content-wrap">
                    <img src={ProfilePicture} alt="Personal Picture"/>
                    <h1 className="example">Christelle Ndaya</h1>

                    <h5>Software Engineer</h5>

                    <p>“A good programmer is someone
                        who always looks both ways before crossing a one-way street.” - Doug Linder, computer
                        scientist.</p>

                    <div className="d-flex align-items-center justify-content-center">
                        <span className="me-2 fw-lighter">Christelle's Resume</span>
                        <a href={resumeUrl} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLink}/>
                        </a>

                    </div>
                </div>
            </header>
            {/* <p>Add another paragraph if needed.</p>> */}
            {/* </header> */}

            {/*  ********************  PROJECTS / PORTFOLIO  ********************* */}
            <section className="projects">
                <div className="content-wrap">
                    <h2 className=" example example2">Featured Projects</h2>
                    {/* <p>View selected projects below. More information can be found at <a className="btn"
                        href="http://christellendaya.com">christellendaya.com</a>.</p> */}

                    {/*  Copy the whole <section> block to add more projects. */}
                    <section>

                        <h5>JP Morgan Chase Hackathon</h5>

                        <ul>
                            <li>Implemented a new, more user-friendly website for a Non-Profit organization.</li>
                            <li>Technologies: React, React Bootstrap, Node.js</li>
                        </ul>


                        <h5>Glia Health</h5>
                        <ul>
                            <li>Capstone Senior Design Project developed with Flutter, Dart, and AWS</li>
                            <li>Implemented modern approaches to neurological disease care.</li>
                            <li><a href="https://www.linkedin.com/company/glia-health/posts/?feedView=all">Glia
                                Health</a></li>
                        </ul>


                        <h5>WhatsApp Clone App</h5>
                        <ul>
                            <li>Designed an instant messaging that allows users to send text messages with React,
                                Firebase, Redux, Material UI
                            </li>
                            <li>Implemented Google authentication to verify user identity before granting access to the
                                website
                            </li>
                            <li><a href=" whatsapp-clone-e33b7.firebaseapp.com/">WhatsApp Clone</a></li>
                        </ul>


                        <h5>Exercise Tracker App</h5>
                        <ul>
                            <li>Developed a MERN (MongoDB, Express, React, Node) web app that align daily tasks and
                                activities. This app features registration and management of users and exercises
                            </li>
                            <li><a href=" https://exercise-tracker-df7a0.web.app/">Exercise Tracker</a></li>
                        </ul>

                        <h5>Parking Lot Management System</h5>
                        <ul>
                            <li>C++ project implementing classNamees.</li>
                            <li>Shows parking details and available parking slots.</li>
                            <li>Registers new users and updatesinformation.</li>
                            <li>Allows users to pay and appeal issued tickets.</li>
                        </ul>


                        <h5>Quiz App</h5>
                        <ul>
                            <li>Quiz application implemented with HTML, CSS, and JavaScript.</li>
                            <li>Save high scores in Local Storage. Create a progress bar. Create a spinning loader
                                icon.
                            </li>
                            <li> Dynamically generate HTML in JavaScript.</li>
                            <li> Fetch trivia questions from
                                Open Trivia DB API
                            </li>
                        </ul>

                        <h5>Note App (UI)</h5>
                        <ul>
                            <li>Note application implemented with TypeScript and React.</li>
                            <li>Offers an intuitive interface that allows users to quickly create, edit, and organize their notes with ease.
                            </li>
                            <li> Markdown Supported Note Taking with Categories</li>
                            <li><a href=" //notes-17f8d.web.app/">Notes</a></li>
                        </ul>

                        <h5>Employee Database</h5>
                        <ul>
                            <li>C++ project implementing hash tables and hash functions with different collision
                                strategies.
                            </li>
                            <li> Maintains and stores employee data by doing the following operations: insert,
                                select, update, and delete data.
                            </li>

                        </ul>

                        {/* <a className="btn" href="#" target="_blank">View project / case study</a>  */}
                    </section>
                </div>
                {/*  End of Project block. */}
            </section>

            {/*  ***********************  WORK EXPERIENCE  *********************** */}
            < section className="work-experience">
                <div className="content-wrap item-details">
                    <h2>Work Experience</h2>
                    {/* <p>Optional paragraph for work experience summary. Not a part of the job details. Delete if not being
                    used.
        </p> */}

                    {/*  Copy this whole <section> block to add more jobs. */}

                    <section className="job-item">
                        <div className="job-details">
                            <h3>J.P Morgan Chase and Co.</h3>
                            <p>Software Engineer</p>
                            <p>07/2022 - Present</p>


                        </div>
                        <div className="job-summary">
                            <ul>
                                <li>Work as a Full-Stack Developer</li>
                                <li> Expertise in developing and maintaining large-scale, real-time applications</li>
                                <li>Experience in developing and deploying web, API (RESTful) and console applications
                                    using Amazon Web Services
                                </li>

                            </ul>
                        </div>
                    </section>

                    <section className="job-item">
                        <div className="job-details">
                            <h3>We Care Insurance</h3>
                            <p>Software Engineer Intern</p>
                            <p>07/2021 - 09/2021</p>


                        </div>
                        <div className="job-summary">
                            <ul>
                                <li>Developing applications (coding, programming) - PHP</li>
                                <li>Debugging and testing code</li>
                                <li>Documenting and testing new software applications</li>
                                <li>Researching, investigating, and fixing a wide range of technical issues</li>
                                <li>Collaborating with senior leaders</li>
                                <li>Approaching problems and creating solutions</li>
                                <li>Proactively learning about new technologies</li>
                            </ul>
                        </div>
                    </section>


                    <section className="job-item">
                        <div className="job-details">
                            <h3>Math Tutor</h3>
                            <p>Brookhaven College</p>
                            <p>02/2016 - 07/2018</p>


                        </div>
                        <div className="job-summary">
                            <ul>
                                <li>Assisted students in developmental math, college algebra, trigonometry,
                                    pre-calculus,and
                                    calculus.
                                </li>
                                <li>Helped students improve their math skills by showing efficient techniques to solve
                                    problems.
                                </li>
                                <li>Adapted explanations based on student comprehension.</li>
                            </ul>
                        </div>
                    </section>

                    <section className="job-item">
                        <div className="job-details">
                            <h3>Math Clerk</h3>
                            <p>Brookhaven College</p>
                            <p>02/2016 - 07/2018</p>
                        </div>
                        <div className="job-summary">
                            <ul>
                                <li>Handling office tasks.</li>
                                <li>Signing visitors In and Out.</li>
                                <li>Setting up meetings.</li>
                                <li>Providing real-time scheduling support by booking appointments and preventing
                                    conflict.
                                </li>
                            </ul>
                        </div>
                    </section>

                    <section className="job-item">
                        <div className="job-details">
                            <h3>Internship, Human Resources</h3>
                            <p>Central Bank of the Congo</p>
                            <p>05/2013 - 06/2013</p>
                        </div>

                        <div className="job-summary">
                            <ul>

                                <li>Receive, stamp, sort, and dispatch documents received from commercial banks
                                    andprivates
                                    companies
                                </li>
                                <li>Make copies and scans of documents</li>
                            </ul>
                        </div>
                    </section>

                    {/* End of Job block. */}
                </div>
            </section>

            {/*  ******************  EDUCATION & CERTIFICATIONS ****************** */}
            < section className="education">
                <div className="content-wrap  item-details">
                    <h2>Education</h2>

                    {/*  Copy this whole <section> block to add more schools. */}
                    <section>
                        <h3>University of North Texas - Denton, TX</h3>
                        <p>B.S in Computer Science</p>
                        <p>May 2022</p>
                        <p>GPA 3.05</p>

                        <h3>Brookhaven College</h3>
                        <p>Associate of Science</p>
                        <p>July 2018</p>
                        <p>GPA 3.4</p>
                    </section>
                    {/* End of School block. */}
                </div>
            </section>

            {/*  *****************  CONTACT INFO / SOCIAL MEDIA  ***************** */}
            < footer>
                <div className="content-wrap">
                    <h2>Let's Keep in Touch!</h2>

                    {/* Social media and contact links. Add or remove any networks.   */}
                    <ul className="contact-list">
                        <li style={{display: 'flex', alignItems: 'center'}}>
                            <div onClick={handleEnvelopeClick} style={{cursor: 'pointer'}}>
                                <FontAwesomeIcon icon={faEnvelope} size={'2x'}/>
                            </div>
                            {isEmailVisible && (
                                <span style={{marginLeft: '10px'}}>
                                       cndaya5@gmail.com
                                 </span>
                            )}
                        </li>
                        <li>
                            <a href={linkedInProfileUrl} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} size={'2x'}/>
                            </a>
                        </li>

                    </ul>
                </div>
            </footer>
        </div>
    )
}
